import { brand, grey, spacing, white, Eyebrow, Flex } from '@pelotoncycle/design-system';
import StyledIcon from '@pelotoncycle/design-system/icons/AsyncIcon';
import React, { useContext, useRef } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '@peloton/react';
import { defaultTransition } from '@peloton/styles';
import { Link } from '@ecomm/internationalize-ui';
import { NavMenuArrow as MenuArrowIcon } from '../Icons/NavMenuArrow';
import { NavContext } from '../NavProvider';
import { baseTopNavStyles, navHoverOpacity } from '../styles';
import type {
  DealsSubNavProps,
  ProductSubNavProps,
  ProductSubNavMenuItemProps,
  ProductSubNavDirectLinkItemProps,
} from '../types';
import { ProductSubNavType } from '../types';
import { isNavMenuOpen } from '../utils';
import ProductNavMenu from './ProductNavMenu';

export const ProductSubNavRow: FC<{
  dealsSubNav: DealsSubNavProps;
  productSubNavs: ProductSubNavProps[];
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}> = ({ dealsSubNav, productSubNavs, setIsMenuOpen }) => {
  const { selectedNavItem, setSelectedNavItem } = useContext(NavContext);

  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    setSelectedNavItem(null);
  });

  React.useEffect(() => {
    setIsMenuOpen(selectedNavItem !== null);
  }, [selectedNavItem, setIsMenuOpen]);

  return (
    <Flex
      ref={containerRef}
      justifyContent="center"
      alignItems="center"
      gap={spacing[40]}
      style={{
        height: '100%',
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 0,
      }}
      data-test-id="productsMenuList"
    >
      {dealsSubNav.promo?.showDealsInNav && (
        <StyledSubNavContainer>
          <ProductSubNavDirectLinkItem
            title={dealsSubNav.subNavComponent.text}
            directLink={dealsSubNav.subNavComponent.link}
            textColor={brand.accent}
          />
        </StyledSubNavContainer>
      )}
      {productSubNavs?.map(productSubNav => (
        <StyledSubNavContainer key={productSubNav?.title}>
          {productSubNav.typeOfProductSubNav.type === ProductSubNavType.MenuDropDown ? (
            <>
              <ProductSubNavMenuItem
                key={productSubNav?.title}
                title={productSubNav?.title}
                isMenuOpen={isNavMenuOpen(selectedNavItem, productSubNav.title)}
              />
              {isNavMenuOpen(selectedNavItem, productSubNav.title) && (
                <ProductNavMenu {...productSubNav} />
              )}
            </>
          ) : (
            <ProductSubNavDirectLinkItem
              title={productSubNav?.title}
              directLink={productSubNav.typeOfProductSubNav?.directLink!}
              textColor={white}
            />
          )}
        </StyledSubNavContainer>
      ))}
    </Flex>
  );
};

const ProductSubNavMenuItem: FC<ProductSubNavMenuItemProps> = ({
  title,
  isMenuOpen = false,
}) => {
  const { setSelectedNavItem } = useContext(NavContext);

  const clickHandler = () => {
    const navItem = isMenuOpen ? null : title;
    setSelectedNavItem(navItem);
  };

  return (
    <StyledNavButton onClick={clickHandler}>
      <StyledNavButtonText>
        <ProductSubNavText title={title} textColor={white} />
        <StyledIcon
          name="chevron"
          height={8}
          primaryColor={grey[60]}
          rotate={isMenuOpen ? 90 : -90}
        />
      </StyledNavButtonText>
      <MenuArrowIcon isMenuOpen={isMenuOpen} />
    </StyledNavButton>
  );
};

const ProductSubNavDirectLinkItem: FC<ProductSubNavDirectLinkItemProps> = ({
  title,
  directLink,
  textColor,
}) => {
  return (
    <StyledNavLink to={directLink?.url} href={directLink?.url}>
      <StyledNavButtonText>
        <ProductSubNavText title={title} textColor={textColor} />
      </StyledNavButtonText>
    </StyledNavLink>
  );
};

const ProductSubNavText: FC<{ title: string; textColor: string }> = ({
  title,
  textColor,
}) => {
  return (
    <Eyebrow size={'medium'} textColor={textColor}>
      {title}
    </Eyebrow>
  );
};

export default ProductSubNavRow;

const StyledSubNavContainer = styled(Flex)`
  position: relative;
  height: 100%;
`;

const baseProductSubNavStyles = `
  ${baseTopNavStyles}
  ${defaultTransition()}
`;

const StyledNavButton = styled.button`
  ${baseProductSubNavStyles}
`;

const StyledNavLink = styled(Link)`
  ${baseProductSubNavStyles}
`;

const StyledNavButtonText = styled(Flex)`
  align-items: center;
  gap: ${spacing[8]};
  ${navHoverOpacity}
`;
